<template>
  <div class="ml-4" style="width: 100%">
    <search-filter
      ref="searchFilter"
      :headers="headersWithoutIgnoredValues"
      :multiSelectColumns="multiSelectColumns"
      :columnsWithPredicates="columnsWithPredicates"
      :billingFeeTypes="billingFeeTypes"
      :dropDownValues="dropDownValues"
      :isPredicates="isPredicates"
      :containsPredicates="containsPredicates"
      :inGroupPredicates="inGroupPredicates"
      :hasPredicates="hasPredicates"
      :doesNotHavePredicates="doesNotHavePredicates"
      :dateColumns="dateColumns"
      :filters="filters"
      :results="results"
      :totalResults="totalResults"
      :totalDistinctResults="totalDistinctResults"
      :theme="theme"
      :showCalendarChip="true"
      :showActiveAccountsChip="false"
      :quickSearchParam="quickSearchFeeReview"
      :refresh="refresh"
      :setQuickSearchParam="setQuickSearchFeeReview"
      :resetQuickSearchParam="resetQuickSearchFeeReview"
      :loadPrepopulatedData="loadPrepopulatedData"
    ></search-filter>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import SearchFilter from "./SearchFilter.vue";

export default {
  props: [
    "headers",
    "theme",
    "results",
    "totalResults",
    "totalDistinctResults",
    "refresh"
  ],
  components: {
    SearchFilter
  },
  data: () => ({
    multiSelectColumns: ["status", "billingType", "accountStatus", "custodian"],
    ignoredHeaderValues: [
      "aum",
      "aumDate",
      "brokerageAccountNumber",
      "billingRunType"
    ],
    dateColumns: ["billingStartDate", "billingEndDate"],
    columnsWithPredicates: [
      "id",
      "name",
      "firm",
      "repCode",
      "paidBy",
      "billingFeeType"
    ],
    isPredicates: ["id", "name", "firm", "repCode", "paidBy"],
    containsPredicates: ["id", "name", "firm", "repCode", "paidBy"],
    inGroupPredicates: ["id", "name", "paidBy"],
    hasPredicates: ["billingFeeType"],
    doesNotHavePredicates: ["billingFeeType"]
  }),
  computed: {
    ...mapState("filters", ["feeReviewFilters", "quickSearchFeeReview"]),
    ...mapState("prepopulatedData", [
      "statuses",
      "feeFrequencies",
      "accountStatuses",
      "custodians",
      "billingFeeTypes"
    ]),
    headersWithoutIgnoredValues() {
      let filteredHeaders = this.headers.filter(
        (x) => !this.ignoredHeaderValues.includes(x.value)
      );
      filteredHeaders.unshift({
        text: "Quick search",
        value: "quickSearch"
      });
      filteredHeaders.push({
        text: "Fee Schedule Type",
        value: "billingFeeType"
      });
      return filteredHeaders;
    },
    filters: {
      get() {
        return this.feeReviewFilters;
      },
      set(newValue) {
        this.setFeeReviewFilters(newValue);
      }
    },
  },
  methods: {
    ...mapMutations("filters", [
      "setFeeReviewFilters",
      "setQuickSearchFeeReview",
      "resetQuickSearchFeeReview"
    ]),
    ...mapActions("prepopulatedData", ["loadFeeReviewPrepopulatedData"]),
    dropDownValues(column) {
      switch (column) {
        case "status":
          return this.statuses;
        case "billingType":
          return this.feeFrequencies;
        case "accountStatus":
          return this.accountStatuses;
        case "custodian":
          return this.custodians;
        case "billingFeeType":
          return this.billingFeeTypes;
        default:
          return [];
      }
    },
    async loadPrepopulatedData() {
      await this.loadFeeReviewPrepopulatedData();
    }
  },
};
</script>
