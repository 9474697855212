<template>
  <span style="display:flex; align-items:center;">
    <span>
      <v-container v-if="showCalcStatus" style="width: 250px;" class="pt-0 pb-0 pr-0">
        <error-dialog
          message="Unable to load latest calculation status! Try again."
          :showDialog.sync="calcStatusErrorDialog"
        ></error-dialog>
        <warning-dialog
          message="There is no calculations history."
          :showDialog.sync="calcStatusWarningDialog"
        ></warning-dialog>
        <latest-calculation-info
          :showDialog.sync="showCalcDetailsDialog"
          :getLatestStatus="getLatestCalculationStatus"
          :percentage="percentage"
          :showWarningIcon="showWarningIcon"
          :failed="failed"
        ></latest-calculation-info>
        <v-icon
          v-if="latestStatus"
          class="pl-1 pr-1 info-icon after-disabled"
          @click="showDetails"
        >
          mdi-information-outline
        </v-icon>
        <progress-percentage
          :getLatestStatus="getLatestCalculationStatus"
          :showRefreshIcon="true"
          :percentage="percentage"
          :showWarningIcon="showWarningIcon"
          :failed="failed"
        ></progress-percentage>
      </v-container>
    </span>
    <span style="margin-top:8px; padding-bottom: 8px" class="mr-2 heigh">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            @click="toggleStatus"
            :class="{
              'after-disabled': true,
              'zoom-in-animation': true,
              'color-active': showCalcStatus
            }"
          >
            mdi-calculator
          </v-icon>
        </template>
        <span>
          <span v-if="showCalcStatus"> Hide </span>
          <span v-else> Show </span>
          calculation status
        </span>
      </v-tooltip>
    </span>
  </span>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import LatestCalculationInfo from "@/components/billing-records/LatestCalculationInfo.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import ProgressPercentage from "@/components/common/ProgressPercentage.vue";
export default {
  props: ["toggleCalcStatus", "showCalcStatus"],
  components: {
    LatestCalculationInfo,
    ErrorDialog,
    WarningDialog,
    ProgressPercentage,
  },
  data: () => ({
    showCalcDetailsDialog: false,
  }),
  computed: {
    ...mapState("billingRecordsCalculations", ["latestRunStatus"]),
    ...mapState("dialogs", ["calcStatusError", "calcStatusWarning"]),
    calcStatusErrorDialog: {
      get() {
        return this.calcStatusError;
      },
      set() {
        this.setCalcStatusError();
      }
    },
    calcStatusWarningDialog: {
      get() {
        return this.calcStatusWarning;
      },
      set() {
        this.setCalcStatusWarning();
      }
    },
    latestStatus() {
      return this.latestRunStatus;
    },
    percentage() {
      if (
        this.latestStatus === null ||
        this.latestStatus.numberOfFinished === null ||
        this.latestStatus.numberOfFailed === null ||
        this.latestStatus.totalAccounts === null
      ) {
        return 0;
      }
      let percent = Math.floor(
        ((this.latestStatus.numberOfFinished +
          this.latestStatus.numberOfFailed) /
          this.latestStatus.totalAccounts) *
          100
      );
      return percent;
    },
    showWarningIcon() {
      return (
        this.latestStatus && this.latestStatus.status == "CompletedWithErrors"
      );
    },
    failed() {
      return this.latestStatus && this.latestStatus.status == "Failed";
    },
  },
  methods: {
    ...mapMutations("dialogs", ["setCalcStatusError", "setCalcStatusWarning"]),
    ...mapActions("billingRecordsCalculations", ["getLatestCalculationStatus"]),
    async showDetails() {
      await this.getLatestCalculationStatus();
      this.showCalcDetailsDialog = true;
    },
    async toggleStatus() {
      if (!this.showCalcStatus) {
        await this.getLatestCalculationStatus();
      }
      this.toggleCalcStatus();
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-active {
  color: teal;
}
</style>
