<template>
  <v-container fluid>
    <error-dialog
      :message="errorMessage"
      :showDialog.sync="showErrorDialog"
    ></error-dialog>
    <error-dialog
      :message="previewPayoutErrorMessage"
      :showDialog.sync="payoutPreviewError"
    ></error-dialog>
    <error-dialog
      :message="previewCustodianErrorMessage"
      :showDialog.sync="custodianPreviewError"
    ></error-dialog>
    <warning-dialog
      :message="warningMessage"
      :showDialog.sync="showWarningDialog"
    ></warning-dialog>
    <warning-dialog
      message="Custodian file does not contain accounts."
      :showDialog.sync="showCustodianFileWarningDialog"
    ></warning-dialog>
    <warning-dialog
      message="Payout file does not contain accounts."
      :showDialog.sync="showPayoutFileWarningDialog"
    ></warning-dialog>
    <running-dialog
      message="Updating properties. Please wait..."
      :showDialog.sync="updatingProperties"
    ></running-dialog>
    <error-dialog
      message="Update error! Try again."
      :showDialog.sync="updatingError"
    ></error-dialog>
    <success-dialog
      message="Updated successfully"
      :showDialog.sync="updateSuccess"
    ></success-dialog>
    <running-dialog
      message="Getting billing records. Please wait..."
      :showDialog.sync="gettingBillingRecords"
    ></running-dialog>
    <error-dialog
      message="Billing Records Error! Try again."
      :showDialog.sync="showBillingRecordsErrorDialog"
    ></error-dialog>
    <error-dialog
      message="Run calculation error!"
      :showDialog.sync="showCalcErrorDialog"
    ></error-dialog>
    <warning-dialog
      :message="calculationWarningMessage"
      :showDialog.sync="calculationWarningDialog"
    ></warning-dialog>
    <running-dialog
      message="Running calculation..."
      :showDialog.sync="runningCalculation"
    ></running-dialog>
    <success-dialog
      :message="runStartedMessage"
      :showDialog.sync="runCalcStartSuccess"
    ></success-dialog>
    <empty-exports-dialog
      :showDialog.sync="showEmptyExportsDialog"
    ></empty-exports-dialog>
    <error-dialog
      message="Export Error!"
      :showDialog.sync="showExportErrorDialog"
    ></error-dialog>
    <success-dialog
      :message="exportStartedMessage"
      :showDialog.sync="exportStarted"
    ></success-dialog>
    <warning-dialog
      :message="exportWarningMessage"
      :showDialog.sync="exportWarningDialog"
    ></warning-dialog>
    <info-dialog
      :message="exportInfoMessage"
      :showDialog.sync="showExportInfoMessage"
    ></info-dialog>
    <column-configurator
      :showDialog.sync="showConfiguratorDialog"
      :defaultHeaders="defaultHeaders"
      :savedHeaders.sync="savedHeaders"
      :nameOfSetting="feeReviewTableSettingName"
    ></column-configurator>
    <v-card>
      <v-card-title class="position-top">
        <v-row justify="space-between" class="ml-0 mr-0 height">
          <span class="mb-9 header-position"> {{ cardTitle }} - Set Account Properties </span>
          <span id="icons " class="mb-9 progress-icon_position"  style="display: flex">
            <span>
              <latest-custodian-export-status
                :showCustodianExportStatus="showCustodianExportStatus"
                :toggleCustodianExportStatus="toggleCustodianExportStatus"
              ></latest-custodian-export-status>
            </span>
            <span>
              <latest-payout-export-status
                :showPayoutExportStatus="showPayoutExportStatus"
                :togglePayoutExportStatus="togglePayoutExportStatus"
              ></latest-payout-export-status>
            </span>
            <span>
              <latest-calculation-status
                :toggleCalcStatus="toggleCalcStatus"
                :showCalcStatus="showCalcStatus"
              ></latest-calculation-status>
            </span>
          </span>
        </v-row>
      </v-card-title>
      <change-properties
        :showDialog.sync="showChangeProperties"
        :accounts="selected"
        :theme="theme"
        :allSelected="allSelected"
        :billingPeriod="selectedDate"
        runType="Regular"
        :filters="feeReviewFilters"
        :quickSearch="quickSearchFeeReview"
        @confirm="confirmUpdate"
      ></change-properties>
      <div style="display: flex; justify-content: space-between;">
        <span style="width: 95%;" class="bottom-align">
          <fee-review-filter
            ref="filters"
            :headers="headersSelected"
            :theme="theme"
            :results="accounts"
            :totalResults="totalAccounts"
            :totalDistinctResults="totalDistinctAccounts"
            :refresh="refresh"
          ></fee-review-filter>
        </span>
        <span style="width: 5%;" class="bottom-align">
          <v-container fluid>
            <v-row justify="end">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="showConfigurator">
                    <v-icon :color="isColumnConfiguratorUsed ? theme.icon : ''">
                      mdi-cog
                    </v-icon>
                  </v-btn>
                </template>
                <span> Choose columns </span>
              </v-tooltip>
            </v-row>
          </v-container>
        </span>
      </div>
      <advanced-account-table
        screen="feeReview"
        :headersSelected="headersSelected"
        :pagination.sync="options"
        :accounts="accounts"
        :totalAccounts="totalAccounts"
        :totalDistinctAccounts="totalDistinctAccounts"
        :selectedAccounts.sync="selected"
        :filters="feeReviewFilters"
        :allSelected="allSelected"
        :loading="loading"
        :theme="theme"
        :refresh="refresh"
        :refreshOrCut="refreshOrCut"
      ></advanced-account-table>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span class="number-effect_space">
          <v-row>
            <v-col cols="12" md="8">
              <v-card-text class="py-0 mx-1 my-1" style="min-width: 220px">
                Accounts selected:
                <span :class="activeEffect && 'number-effect'">
                  {{ totalSelected }}
                </span>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                class="py-0 mx-1 my-1"
                label="ALL"
                v-model="allSelected"
                :color="theme.switch"
              ></v-switch>
            </v-col>
          </v-row>
        </span>
      </v-row>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span>
          <regular-button
            buttonText="Select None"
            :theme="theme"
            :disabled="buttonDisabled"
            :action="selectNone"
          ></regular-button>
        </span>
        <span>
          <div class="button-pair">
            <button-with-tooltip
              buttonText="Set Account Properties"
              :tooltipText="propertiesTooltipText"
              :theme="theme"
              :disabled="buttonLimits"
              :action="changeProperties"
            ></button-with-tooltip>
            <button-with-tooltip
              buttonText="Account Status & History"
              :tooltipText="accountStatusTooltipText"
              :theme="theme"
              :disabled="buttonLimits"
              :action="showAccountStatus"
            ></button-with-tooltip>
          </div>
          <div class="button-pair">
            <v-tooltip top nudge-top="10" max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" :class="{ 'hover-disabled': runButtonDisabled }">
                  <v-btn
                    tile
                    v-bind="attrs"
                    class="mb-2 ml-2 btn-letter_transform"
                    :color="runButtonColor"
                    :dark="!runButtonDisabled && !disallowedStatus"
                    @click="runCalc"
                    :disabled="runButtonDisabled || disallowedStatus"
                  >
                    Calculate Fees
                  </v-btn>
                </span>
              </template>
              <span v-if="!runButtonDisabled">
                <span v-if="disallowedStatus">
                  {{ disallowedStatusMessage }}
                </span>
                <span v-else>
                  <p class="mb-0">
                    Calculate fee amounts for accounts & sleeves.<br />
                    Set billing record status to "Calculated".<br />
                    ({{ formattedPeriod }} billing data)
                  </p>
                </span>
              </span>
            </v-tooltip>
            <button-with-tooltip
              buttonText="Review Fees"
              :tooltipText="reviewFeesTooltipText"
              :theme="theme"
              :disabled="buttonDisabled || !validDate"
              :action="showBillingRecords"
            ></button-with-tooltip>
          </div>
          <div class="button-pair">
            <button-with-tooltip
              buttonText="Preview Custodian File"
              :tooltipText="previewCustodianTooltipText"
              :theme="theme"
              :disabled="buttonLimits"
              :loading="downloadingCustodianPreview"
              :action="downloadCustodianPreview"
            ></button-with-tooltip>
            <button-with-tooltip
              buttonText="Export Custodian File"
              :tooltipText="exportCustodianTooltipText"
              :theme="theme"
              :disabled="buttonLimits"
              :action="generateCustodian"
            ></button-with-tooltip>
          </div>
          <div class="button-pair mr-2">
            <button-with-tooltip
              buttonText="Preview Payout File"
              :tooltipText="previewPayoutTooltipText"
              :theme="theme"
              :disabled="buttonLimits"
              :loading="downloadingXtivaPreview"
              :action="downloadXtivaPreview"
            ></button-with-tooltip>
            <button-with-tooltip
              buttonText="Export Payout File"
              :tooltipText="exportPayoutTooltipText"
              :theme="theme"
              :disabled="buttonLimits"
              :action="generatePayout"
            ></button-with-tooltip>
          </div>
        </span>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { exportFileExtension } from "@/config/organization-config.js";
import {
  getMonthYearFromYearMonthPeriod,
  formatBillingRunType
} from "@/utils/format-util.js";
import {
  getCurrentPeriod,
  getBillingRunStringPlusOneFromInt,
  getPreviousRunIntFromString
} from "@/utils/period-util.js";
import amplitude from 'amplitude-js'

import AdvancedAccountTable from "@/components/common/AdvancedAccountTable.vue";
import ColumnConfigurator from "@/components/dialogs/ColumnConfigurator.vue";
import ChangeProperties from "@/components/dialogs/ChangeProperties.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import InfoDialog from "@/components/dialogs/InfoDialog.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";
import EmptyExportsDialog from "@/components/fee-results/dialogs/EmptyExportsDialog.vue";
import cookies from "vue-cookies";
import LatestCalculationStatus from "@/components/billing-records/LatestCalculationStatus.vue";
import FeeReviewFilter from "@/components/filters/FeeReviewFilter.vue";
import LatestPayoutExportStatus from "@/components/exports/LatestPayoutExportStatus.vue";
import LatestCustodianExportStatus from "@/components/exports/LatestCustodianExportStatus.vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import RegularButton from "@/components/common/RegularButton.vue";

export default {
  components: {
    ChangeProperties,
    ColumnConfigurator,
    WarningDialog,
    ErrorDialog,
    RunningDialog,
    InfoDialog,
    SuccessDialog,
    EmptyExportsDialog,
    LatestCalculationStatus,
    FeeReviewFilter,
    LatestPayoutExportStatus,
    LatestCustodianExportStatus,
    AdvancedAccountTable,
    ButtonWithTooltip,
    RegularButton
  },
  data: () => ({
    numberOfLoadings: 0,
    loading: false,
    activeEffect: false,
    showErrorDialog: false,
    errorMessage: "",
    defaultErrorMessage: "Error! Try again.",
    payoutPreviewError: false,
    previewPayoutErrorMessage: "",
    custodianPreviewError: false,
    previewCustodianErrorMessage: "",
    dateBiggerThanMax: true,
    warningMessage: "",
    showWarningDialog: false,
    showCustodianFileWarningDialog: false,
    showPayoutFileWarningDialog: false,
    updateSuccess: false,
    runningCalculation: false,
    gettingBillingRecords: false,
    showBillingRecordsErrorDialog: false,
    showCalcStatus: false,
    showCalcErrorDialog: false,
    runCalcStartSuccess: false,
    runStartedMessage: "",
    calculationWarningDialog: false,
    calculationWarningMessage: "",
    showCustodianExportStatus: false,
    showPayoutExportStatus: false,
    showEmptyExportsDialog: false,
    showExportErrorDialog: false,
    exportStarted: false,
    exportStartedMessage: "",
    exportWarningDialog: false,
    exportWarningMessage: "",
    showExportInfoMessage: false,
    exportInfoMessage: "",
    downloadingXtivaPreview: false,
    downloadingCustodianPreview: false,
    showChangeProperties: false,
    showConfiguratorDialog: false,
    defaultHeaders: [
      {
        text: "ID",
        value: "id",
        sortable: false,
        align: "center",
        selected: true,
      },
      {
        text: "Account Number",
        value: "name",
        selected: true,
      },
      {
        text: "Firm",
        value: "firm",
        selected: true,
      },
      {
        text: "Rep Code",
        value: "repCode",
        selected: true,
      },
      {
        text: "Status",
        value: "status",
        selected: true,
      },
      {
        text: "AUM",
        value: "aum",
        sortable: false,
        align: "end",
        selected: true,
      },
      {
        text: "AUM Date",
        value: "aumDate",
        sortable: false,
        selected: true,
      },
      {
        text: "Fee Frequency",
        value: "billingType",
        selected: true,
      },
      {
        text: "Start Date",
        value: "billingStartDate",
        selected: true,
      },
      {
        text: "End Date",
        value: "billingEndDate",
        selected: true,
      },
      {
        text: "Paid By",
        value: "paidBy",
        selected: true,
      },
      {
        text: "Custodian",
        value: "custodian",
        selected: true,
      },
      {
        text: "Account Status",
        value: "accountStatus",
        selected: true,
      },
      {
        text: "Billing Run Type",
        value: "billingRunType",
        selected: true,
      },
    ],
    savedHeaders: [],
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "colorThemeAll", "rowsPerPage"]),
    ...mapState("feeResults", ["cardTitle"]),
    ...mapState("feeResultsAccounts", [
      "accounts",
      "totalAccounts",
      "totalDistinctAccounts",
      "feeResultsAllSelected",
      "selectedAccounts",
      "totalSelectedAccounts",
      "pagination",
      "selectedDate",
      "maxItems",
    ]),
    ...mapState("feeResultsRecords", ["accountsForCalculation"]),
    ...mapState("billingRecordsCalculations", [
      "defaultPagination",
      "disallowedStatusesForRun",
      "disallowedStatusMessage",
    ]),
    ...mapState("addUpdateAccounts", ["updatingProperties", "updateError"]),
    ...mapState("billingRecordsCalculations", ["runCalculationLimit"]),
    ...mapState("filters", ["feeReviewFilters", "quickSearchFeeReview"]),
    ...mapState("columnConfigurator", ["feeReviewTableSettingName"]),
    propertiesTooltipText() {
      return `Account inception and termination properties. <br />
              (${this.formattedPeriod} billing data)`;
    },
    accountStatusTooltipText() {
      return `Set billing record status, show invoice history. <br />
              (${this.formattedPeriod} billing data)`;
    },
    reviewFeesTooltipText() {
      return `Review and adjust fee amounts. <br />
              (${this.formattedPeriod} billing data)`;
    },
    previewCustodianTooltipText() {
      return `Download preview custodian file.<br />
              No change to billing record status.`;
    },
    exportCustodianTooltipText() {
      return `Generate custodian file.<br />
              Retrieve this file in download portal.<br />
              Set billing record status to "Pending".<br />
              (${this.formattedPeriod} billing data)`;
    },
    previewPayoutTooltipText() {
      return `Download preview payout file.<br />
              No change to billing record status.`;
    },
    exportPayoutTooltipText() {
      return `Generate payout file.<br />
              Retrieve this file in download portal.<br />
              Set billing record status to "Paid Out".<br />
              (${this.formattedPeriod} billing data)`;
    },
    calcLimit() {
      return this.runCalculationLimit;
    },
    updatingError: {
      get() {
        return this.updateError;
      },
      set(newValue) {
        this.setUpdateError(newValue);
      },
    },
    isColumnConfiguratorUsed() {
      return this.defaultHeaders.length !== this.headersSelected.length;
    },
    headersSelected() {
      let filteredHeaders = this.savedHeaders.filter(
        (header) => header.selected == true
      );
      return filteredHeaders;
    },
    allSelected: {
      get() {
        return this.feeResultsAllSelected;
      },
      set(newValue) {
        this.setFeeResultsAllSelected(newValue);
      }
    },
    selected: {
      get() {
        return this.selectedAccounts;
      },
      set(newValue) {
        this.setSelectedAccounts(newValue);
      },
    },
    totalSelected: {
      get() {
        if (this.allSelected) {
          return this.totalAccounts;
        } else {
          return this.totalSelectedAccounts;
        }
      },
      set(newValue) {
        this.setTotalSelectedAccounts(newValue);
      },
    },
    options: {
      get() {
        return this.pagination;
      },
      set(newValue) {
        this.setPagination(newValue);
      },
    },
    page() {
      return this.options.page;
    },
    pageSize() {
      return this.options.itemsPerPage;
    },
    sortBy() {
      if (this.options.sortBy && this.options.sortBy.length === 1) {
        return this.options.sortBy[0];
      } else {
        return null;
      }
    },
    sortDirection() {
      if (this.options.sortDesc && this.options.sortDesc.length === 1) {
        return this.options.sortDesc[0] === false ? "Ascending" : "Descending";
      } else {
        return "Ascending";
      }
    },
    formattedPeriod() {
      return getMonthYearFromYearMonthPeriod(this.selectedDate);
    },
    validDate() {
      return this.selectedDate != null;
    },
    disallowedStatus() {
      return this.statusNotAllowed(this.selectedAccounts);
    },
    buttonDisabled() {
      return this.totalSelected == 0;
    },
    runButtonDisabled() {
      return (
        this.buttonDisabled ||
        this.totalSelected > this.calcLimit ||
        this.dateBiggerThanMax
      );
    },
    buttonLimits() {
      return (
        this.buttonDisabled ||
        (!this.allSelected && this.totalSelected > this.maxItems)
      );
    },
    runButtonColor() {
      if (this.totalSelected <= this.calcLimit) {
        return this.theme.button;
      } else {
        return this.theme.error;
      }
    },
    theme() {
      if (this.allSelected) {
        return this.colorThemeAll;
      } else {
        return this.colorTheme;
      }
    },
    quickSearchValue() {
      return this.$refs.filters.$refs.searchFilter.quickSearchValue;
    },
  },
  watch: {
    totalSelected() {
      this.activeEffect = false;
      setTimeout(() => {
        this.activeEffect = true;
      });
    },
    selected(val) {
      this.totalSelected = val.length;
      let accounts = val.map(account => {
        return {
          id: account.id,
          billingRunType: account.billingRunType
        };
      });
      this.setAccountsForCalculation(accounts);
    },
    allSelected(val) {
      if (val === true) {
        this.setQuickSearchFromTempParam();
      }
    },
    selectedDate() {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      this.getMaxDate();
      this.selectNone();
      this.refresh();
      this.changePageTitle(this.$router.currentRoute);
    },
  },
  methods: {
    ...mapActions("pageTitle", ["changePageTitle"]),
    ...mapActions("billingRecordsCalculations", ["getLastBillingRunInt"]),
    ...mapActions("feeResultsAccounts", ["feeReviewSearch"]),
    ...mapActions("feeResultsRecords", [
      "getBillingRecordsPaged",
      "runCalculation",
    ]),
    ...mapMutations("accountStatus", [
      "resetAccountStatusPagination",
      "setAccountStatusFilters",
      "resetAccountStatusFilters",
      "setQuickSearchParam",
      "resetQuickSearchParam",
    ]),
    ...mapMutations("feeResultsAccounts", [
      "setFeeResultsAllSelected",
      "setSelectedAccounts",
      "setTotalSelectedAccounts",
      "setPagination",
      "setSelectedDate",
      "setAccounts",
      "setTotalAccounts",
      "setTotalDistinctAccounts",
    ]),
    ...mapMutations("feeResultsRecords", ["setAccountsForCalculation"]),
    ...mapMutations("addUpdateAccounts", ["setUpdateError"]),
    ...mapActions("addUpdateAccounts", ["updateAccountProperties"]),
    ...mapActions("exports", ["filterExport", "filterPreviewExportFile"]),
    ...mapMutations("billingRecordsCalculations", [
      "setBillingRecordsPagination",
    ]),
    ...mapMutations("filters", ["setQuickSearchFeeReview"]),
    setQuickSearchFromTempParam() {
      if (
        this.quickSearchValue &&
        this.quickSearchValue !== this.quickSearchFeeReview
      ) {
        this.setQuickSearchFeeReview(this.quickSearchValue);
      }
    },
    async getMaxDate() {
      let lastRunInt = await this.getLastBillingRunInt();
      if (!lastRunInt) {
        this.dateBiggerThanMax = true;
        return;
      }
      let lastRunString = getBillingRunStringPlusOneFromInt(lastRunInt);
      if (this.selectedDate > lastRunString) {
        this.dateBiggerThanMax = true;
      } else {
        this.dateBiggerThanMax = false;
      }
    },
    async generateCustodian() {
      amplitude.getInstance().logEvent("Fee Review [Send to Custodian]", {
        "Number of accounts": this.selectedAccounts.length
      });
      await this.generateExport("Custodian");
    },
    async generatePayout() {
      amplitude.getInstance().logEvent("Fee Review [Payout]", {
        "Number of accounts": this.selectedAccounts.length
      });
      await this.generateExport("Payout");
    },
    async generateExport(exportType) {
      let payload = {
        runType: "Regular",
        period: getPreviousRunIntFromString(this.selectedDate),
        exportType: exportType
      };
      if (this.allSelected) {
        payload = { ...payload, all: true };
      } else {
        payload = { ...payload, selectedAccounts: this.selected };
      }
      let result = await this.filterExport(payload);

      switch (result.status) {
        case "SUCCESS":
          this.$router.push({ name: "FeeResultsExports" });
          break;
        case "INFO":
          this.exportInfoMessage = result.message;
          this.showExportInfoMessage = true;
          break;
        case "EMPTY":
          this.showEmptyExportsDialog = true;
          break;
        case "WARNING":
          this.exportWarningMessage = result.message;
          this.exportWarningDialog = true;
          break;
        case "TIMEOUT":
          this.exportStartedMessage = `${exportType} Export is taking too long. Check status in top right corner.`;
          this.exportStarted = true;
          if (exportType === "Custodian") {
            this.showCustodianExportStatus = true;
          } else if (exportType === "Payout") {
            this.showPayoutExportStatus = true;
          }
          break;
        default:
          this.showExportErrorDialog = true;
      }
    },

    async downloadXtivaPreview() {
      this.setQuickSearchFromTempParam();
      if (!this.downloadingXtivaPreview) {
        this.downloadingXtivaPreview = true;
        let payload = {
          runType: "Regular",
          period: getPreviousRunIntFromString(this.selectedDate),
          exportType: "Payout"
        };
        if (this.allSelected) {
          payload = { ...payload, all: true };
        } else {
          payload = { ...payload, selectedAccounts: this.selected };
        }
        const result = await this.filterPreviewExportFile(payload);

        switch (result.status) {
          case "SUCCESS":
            {
              const data = URL.createObjectURL(result.data);
              const link = document.createElement("a");

              link.setAttribute("href", data);
              let extension = exportFileExtension(cookies.get("organization"));
              //logic for change date: chosen month - 1
              let chosenYear = parseInt(this.selectedDate.slice(0, 4));
              let chosenMonth = parseInt(this.selectedDate.slice(-2));
              let newYearMonth = new Date(chosenYear, chosenMonth);
              newYearMonth.setMonth(newYearMonth.getMonth(chosenMonth) - 1);
              let newChosenDate = newYearMonth.toISOString().slice(0, 7);
              const fileName = `Payouts Summary ${newChosenDate}.${extension}`;
              link.setAttribute("download", fileName);
              link.click();

              amplitude
                .getInstance()
                .logEvent("Fee Review [Download Payout File]", {
                  "Number of accounts": this.selectedAccounts.length
                });
            }
            break;
          case "EMPTY":
            this.showPayoutFileWarningDialog = true;
            break;
          case "TIMEOUT":
            this.previewPayoutErrorMessage = "Server timeout! Try again.";
            this.payoutPreviewError = true;
            break;
          default:
            this.previewPayoutErrorMessage = "Payout Preview Error! Try again.";
            this.payoutPreviewError = true;
        }

        this.downloadingXtivaPreview = false;
      }
    },
    async downloadCustodianPreview() {
      this.setQuickSearchFromTempParam();
      if (!this.downloadingCustodianPreview) {
        this.downloadingCustodianPreview = true;
        let payload = {
          runType: "Regular",
          period: getPreviousRunIntFromString(this.selectedDate),
          exportType: "Custodian"
        };
        if (this.allSelected) {
          payload = { ...payload, all: true };
        } else {
          payload = { ...payload, selectedAccounts: this.selected };
        }
        const result = await this.filterPreviewExportFile(payload);

        switch (result.status) {
          case "SUCCESS":
            {
              const data = URL.createObjectURL(result.data);
              const link = document.createElement("a");

              link.setAttribute("href", data);
              let extension = "zip";
              //logic for change date: chosen month - 1
              let chosenYear = parseInt(this.selectedDate.slice(0, 4));
              let chosenMonth = parseInt(this.selectedDate.slice(-2));
              let newYearMonth = new Date(chosenYear, chosenMonth);
              newYearMonth.setMonth(newYearMonth.getMonth(chosenMonth) - 1);
              let newChosenDate = newYearMonth.toISOString().slice(0, 7);
              const fileName = `Custodian Summary ${newChosenDate}.${extension}`;
              link.setAttribute("download", fileName);
              link.click();

              amplitude
                .getInstance()
                .logEvent("Fee Review [Download Custodian File]", {
                  "Number of accounts": this.selectedAccounts.length
                });
            }
            break;
          case "EMPTY":
            this.showCustodianFileWarningDialog = true;
            break;
          case "TIMEOUT":
            this.previewCustodianErrorMessage = "Server timeout! Try again.";
            this.custodianPreviewError = true;
            break;
          default:
            this.previewCustodianErrorMessage = "Custodian Preview Error! Try again.";
            this.custodianPreviewError = true;
        }

        this.downloadingCustodianPreview = false;
      }
    },
    toggleCustodianExportStatus() {
      this.showCustodianExportStatus = !this.showCustodianExportStatus;
    },
    togglePayoutExportStatus() {
      this.showPayoutExportStatus = !this.showPayoutExportStatus;
    },
    toggleCalcStatus() {
      this.showCalcStatus = !this.showCalcStatus;

      amplitude.getInstance().logEvent("Fee Review [Calculation Status]", {
        "Status": (this.showCalcStatus === true ? 'Show' : 'Hide')
      });

    },

    async refreshOrCut(pageSize) {
      let array = this.accounts;
      if (pageSize > array.length) {
        await this.refresh();
      } else {
        this.setAccounts(this.accounts.slice(0, pageSize));
      }
    },
    async refresh(params) {
      this.numberOfLoadings++;
      this.loading = true;

      if (params && params.page) {
        this.options.page = params.page;
      }
      let payload = {
        page: this.page,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
        searchParam: this.quickSearchValue
      };
      let result = await this.feeReviewSearch(payload);
      if (!result) {
        this.errorMessage = this.defaultErrorMessage;
        this.showErrorDialog = true;
      } else if (result.errorMessage) {
        this.errorMessage = result.errorMessage;
        this.showErrorDialog = true;
      }

      this.numberOfLoadings--;
      if (this.numberOfLoadings == 0) {
        this.loading = false;
      }
    },
    async confirmUpdate(payload) {
      payload.feeResults = true;
      if (payload.all) {
        payload = {
          ...payload,
          runType: "Regular",
          period: getPreviousRunIntFromString(this.selectedDate)
        };
      }
      let result = await this.updateAccountProperties(payload);
      if (!result.error) {
        let notUpdatedCount = result.totalFailed;
        if (notUpdatedCount > 0) {
          if (result.all) {
            if (result.allFailed) {
              this.updatingError = true;
            } else {
              this.showWarningDialog = true;
              this.warningMessage = "Warning! Some attributes are not updated.";
            }
          } else {
            this.showWarningDialog = true;
            this.warningMessage =
              "Warning! " + notUpdatedCount + " attributes not updated.";
          }
        } else {
          this.updateSuccess = true;
        }
      }
      await this.refreshAndDeselect();
    },
    selectNone() {
      this.selected = [];
      this.allSelected = false;
    },
    changeProperties() {
      this.setQuickSearchFromTempParam();
      this.showChangeProperties = !this.showChangeProperties;

      amplitude.getInstance().logEvent("Fee Review [Change Properties]", {
        "Number of accounts": this.selectedAccounts.length
      });

    },
    showConfigurator() {
      this.showConfiguratorDialog = true;
    },
    async showAccountStatus() {
      this.setQuickSearchFromTempParam();
      this.resetAccountStatusPagination();
      if (this.allSelected) {
        this.setAccountStatusFilters(this.feeReviewFilters);
        this.setQuickSearchParam(this.quickSearchFeeReview);
      } else {
        this.resetAccountStatusFilters();
        this.resetQuickSearchParam();
      }
      this.$router.push({ name: "AccountStatuses" });

      amplitude.getInstance().logEvent("Fee Review [Account Status]", {
        "Number of accounts": this.selectedAccounts.length
      });

    },
    async showBillingRecords() {
      this.setQuickSearchFromTempParam();
      this.gettingBillingRecords = true;
      let result = await this.getBillingRecordsPaged({
        page: this.defaultPagination.page,
        pageSize: this.defaultPagination.itemsPerPage,
        all: this.allSelected,
        accounts: this.accountsForCalculation
      });
      this.gettingBillingRecords = false;
      if (result) {
        this.setBillingRecordsPagination(this.defaultPagination);
        this.$router.push({
          name: "FeeResultsRecords",
          params: { previousScreen: "FeeResultsAccounts" }
        });

        amplitude.getInstance().logEvent("Fee Review [Show Billing Records]", {
          "Number of accounts": this.selectedAccounts.length
        });

      } else {
        this.showBillingRecordsErrorDialog = true;
      }
    },
    async runCalc() {
      amplitude.getInstance().logEvent("Fee Review [Run Calculation]", {
        "Number of accounts": this.selectedAccounts.length
      });

      this.runningCalculation = true;
      let result = await this.runCalculation({
        page: this.defaultPagination.page,
        pageSize: this.defaultPagination.itemsPerPage,
        all: this.allSelected,
        accounts: this.accountsForCalculation
      });
      this.runningCalculation = false;

      if (result.warningMessage) {
        this.calculationWarningMessage = result.warningMessage;
        this.calculationWarningDialog = true;
      } else if (result.error) {
        this.showCalcErrorDialog = true;
      } else if (result.timeout) {
        this.runCalcStartSuccess = true;
        this.runStartedMessage =
          "Calculation started. Check calculation status in top right corner.";
        this.showCalcStatus = true;
      } else if (result.responseData) {
        this.setBillingRecordsPagination(this.defaultPagination);
        this.$router.push({
          name: "FeeResultsRecords",
          params: { previousScreen: "FeeResultsAccounts" }
        });
      } else {
        this.showCalcErrorDialog = true;
      }
    },
    statusNotAllowed(accounts) {
      if (this.allSelected) {
        return false;
      }
      var i = 0;
      var found = false;
      while (i < accounts.length) {
        if (this.disallowedStatusesForRun.includes(accounts[i].status)) {
          found = true;
        }
        i++;
      }
      return found;
    },
    formatBillingRunType(type) {
      return formatBillingRunType(type);
    },
    async refreshAndDeselect() {
      this.selectNone();
      await this.refresh();
    },
  },
  async beforeMount() {
    this.setFeeResultsAllSelected(false);
    this.setAccounts([]);
    this.setTotalAccounts(0);
    this.setTotalDistinctAccounts(0);
  },
  async mounted() {
    if (this.selectedDate == null) {
      this.setSelectedDate(getCurrentPeriod());
    }
    await this.refreshAndDeselect();
    await this.getMaxDate();
  },
};
</script>

<style>
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #26a69a !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #26a69a !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected:hover {
  background: #26a69a !important;
}
.theme--light.v-data-table tbody tr.v-data-table__selected:hover {
  background: r#26a69aed !important;
}
.height {
  display: flex;
  align-items: center;
}
/* Header and progress bar move top */
.position-top {
  position: relative;
  height: 70px;
}
.button-pair {
  display: inline-block;
  margin-right: 1rem;
}
.hover-disabled {
  pointer-events: none;
}
.header-position {
  position: absolute;
  top: 20px;
  left: 15px;
}
.progress-icon_position {
  position: absolute;
  top: 10px;
  right: 0;
}
.bottom-align {
  display: flex;
  align-items: flex-end;
}
</style>
