var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticStyle:{"display":"flex"}},[(_vm.showCustodianExportStatus)?_c('v-container',{staticClass:"pt-0 pb-0 pr-0",staticStyle:{"width":"250px"}},[_c('error-dialog',{attrs:{"message":"Unable to load latest custodian status! Try again.","showDialog":_vm.showStatusErrorDialog},on:{"update:showDialog":function($event){_vm.showStatusErrorDialog=$event},"update:show-dialog":function($event){_vm.showStatusErrorDialog=$event}}}),_c('span',[_c('latest-custodian-export-info',{attrs:{"showDialog":_vm.showCustodianExportInfo,"invoicesUploadingPercentage":_vm.invoicesUploadingPercentage,"creatingFilesPercentage":_vm.creatingFilesPercentage,"uploadingFilesPercentage":_vm.uploadingFilesPercentage},on:{"update:showDialog":function($event){_vm.showCustodianExportInfo=$event},"update:show-dialog":function($event){_vm.showCustodianExportInfo=$event}}}),_c('span',{staticStyle:{"display":"flex"}},[(_vm.latestStatus)?_c('v-icon',{staticClass:"pl-1 pr-1 info-icon after-disabled",on:{"click":_vm.showDetailsInfo}},[_vm._v(" mdi-information-outline ")]):_vm._e(),_c('progress-percentage',{attrs:{"getLatestStatus":_vm.getLatestCustodianExportStatus,"showRefreshIcon":true,"percentage":_vm.percentage,"failed":_vm.failed}})],1)],1)],1):_vm._e(),_c('span',{staticClass:"mr-0",staticStyle:{"margin-top":"11px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{
            'after-disabled': true,
            'mr-2': true,
            'zoom-in-animation': true,
            'color-active': _vm.showCustodianExportStatus
          },staticStyle:{"margin-bottom":"5px"},attrs:{"id":"swap-vertical_bold"},domProps:{"innerHTML":_vm._s(_vm.icon)},on:{"click":_vm.toggleStatus}},on))]}}])},[_c('span',[(_vm.showCustodianExportStatus)?_c('span',[_vm._v(" Hide ")]):_c('span',[_vm._v(" Show ")]),_vm._v(" Custodian export status ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }