<template>
  <v-dialog v-model="show" max-width="650" @keydown.esc="close">
    <calculation-error-log
      :showDialog.sync="showErrorLogDialog"
      :failLog.sync="failLog"
    ></calculation-error-log>
    <v-card>
      <v-card-title>
        <span class="headline"> Latest Calculation Details </span>
        <span class="right-corner">
          <v-icon @click="close" class="exit-icon after-disabled">
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <div>
        <v-card v-if="latestStatus">
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Started At </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ formatUtcDateTime(latestStatus.startedAt) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Total Calculations </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text> {{ latestStatus.totalAccounts }} </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Number Of Finished </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text> {{ latestStatus.numberOfFinished }} </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Number Of Failed </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ latestStatus.numberOfFailed }}
                <v-btn
                  small
                  v-if="errorLogNotEmpty"
                  class="ml-5"
                  :color="colorTheme.error"
                  dark
                  @click="showErrorLog()"
                >
                  Show error log
                </v-btn>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="finished">
            <v-col cols="12" md="6">
              <v-card-text> Finished At </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ formatUtcDateTime(latestStatus.finishedAt) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Status </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text :style="textColor(latestStatus.status)">
                {{ getStatusDisplayName(latestStatus.status) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Progress </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <progress-percentage
                :getLatestStatus="getLatestStatus"
                :showRefreshIcon="true"
                :percentage="percentage"
                :showWarningIcon="showWarningIcon"
                :failed="failed"
              ></progress-percentage>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card>
        <v-card v-else>
          <v-card-text> No Data </v-card-text>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { formatUtcDateTime } from "@/utils/format-util.js";
import ProgressPercentage from "@/components/common/ProgressPercentage.vue";
import CalculationErrorLog from "./CalculationErrorLog.vue";

export default {
  components: {
    ProgressPercentage,
    CalculationErrorLog
  },
  props: [
    "showDialog",
    "getLatestStatus",
    "percentage",
    "showWarningIcon",
    "failed",
  ],
  emits: ["update:showDialog"],
  data: () => ({
    showErrorLogDialog: false,
    failLog: [],
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "calcStatusColors"]),
    ...mapState("billingRecordsCalculations", ["latestRunStatus"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    latestStatus() {
      return this.latestRunStatus;
    },
    finished() {
      if (
        this.latestStatus &&
        this.latestStatus.status &&
        (this.latestStatus.status === "Completed" ||
          this.latestStatus.status === "Failed" ||
          this.latestStatus.status === "CompletedWithErrors")
      ) {
        return true;
      }
      return false;
    },
    errorLogNotEmpty() {
      return (
        this.latestStatus &&
        this.latestStatus.failLog &&
        this.latestStatus.failLog.length > 0
      );
    },
  },
  methods: {
    showErrorLog() {
      this.failLog = this.latestStatus.failLog;
      this.showErrorLogDialog = true;
    },
    close() {
      this.show = false;
    },
    getStatusDisplayName(status) {
      switch (status) {
        case "Active":
          return "Active";
        case "Completed":
          return "Completed";
        case "Failed":
          return "Failed";
        case "CompletedWithErrors":
          return "Completed with errors";
        default:
          return "Unknown status";
      }
    },
    formatUtcDateTime(date) {
      return formatUtcDateTime(date);
    },
    textColor(status) {
      return {
        color: this.calcStatusColors[status],
        "font-weight": "bolder"
      };
    },
  },
};
</script>
