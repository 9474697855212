<template>
  <v-icon v-if="!showSpinner" class="pl-1 pr-1 refresh-icon after-disabled" @click="getStatus">
    mdi-refresh
  </v-icon>
  <v-progress-circular
    v-else
    class="ml-2 px-2"
    size="20"
    :color="colorTheme.progress"
    indeterminate
  >
  </v-progress-circular>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["getLatestStatus"],
  data: () => ({
    showSpinner: false,
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
  },
  methods: {
    async getStatus() {
      this.showSpinner = true;
      await this.getLatestStatus();
      this.showSpinner = false;
    },
  }
};
</script>

<style scoped>
.refresh-icon:hover {
  color: teal;
}
</style>
