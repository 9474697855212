<template>
  <span style="display:flex">
    <v-container v-if="showPayoutExportStatus" style="width: 250px;" class="pt-0 pb-0 pr-0">
      <span>
        <error-dialog
          message="Unable to load latest payout status! Try again."
          :showDialog.sync="showStatusErrorDialog"
        ></error-dialog>
        <latest-payout-export-info
          :showDialog.sync="showPayoutExportInfo"
          :updatingInvoicesPercentage="invoicesPercentage"
          :creatingFilesPercentage="creatingFilesPercentage"
          :uploadingFilesPercentage="uploadingFilesPercentage"
        ></latest-payout-export-info>
        <span style="display: flex">
          <v-icon
            v-if="latestStatus"
            class="pl-1 pr-1 info-icon after-disabled"
            @click="showDetailsInfo"
          >
            mdi-information-outline
          </v-icon>
          <progress-percentage
            :getLatestStatus="getLatestPayoutExportStatus"
            :showRefreshIcon="true"
            :percentage="percentage"
            :failed="failed"
          ></progress-percentage>
        </span>
      </span>
    </v-container>
    <span style="margin-top:10px" class="mr-0">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div
            v-html="icon"
            v-on="on"
            @click="toggleStatus"
            style="margin-bottom: 5px"
            :class="{
              'after-disabled': true,
              'mr-2': true,
              'mt-1': true,
              'zoom-in-animation': true,
              'color-active': showPayoutExportStatus
            }"
          ></div>
        </template>
        <span>
          <span v-if="showPayoutExportStatus"> Hide </span>
          <span v-else> Show </span>
          Payout export status
        </span>
      </v-tooltip>
    </span>
  </span>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import LatestPayoutExportInfo from "./LatestPayoutExportInfo.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import ProgressPercentage from "@/components/common/ProgressPercentage.vue";
import icons from "@/assets/feeReviewIcons/icons.js";
export default {
  components: {
    ProgressPercentage,
    LatestPayoutExportInfo,
    ErrorDialog
  },
  props: ["showPayoutExportStatus", "togglePayoutExportStatus"],
  data: () => ({
    showPayoutExportInfo: false,
  }),
  computed: {
    ...mapState("exports", ["latestPayoutExportStatus"]),
    ...mapState("dialogs", ["payoutExportStatusError"]),
    showStatusErrorDialog: {
      get() {
        return this.payoutExportStatusError;
      },
      set() {
        this.setPayoutExportStatusError();
      }
    },
    icon() {
      return icons.payoutIcon;
    },
    latestStatus() {
      return this.latestPayoutExportStatus;
    },
    creatingFilesPercentage() {
      if (this.latestStatus === null || !this.latestStatus.fileCreated) {
        return 0;
      } else {
        return 100;
      }
    },
    uploadingFilesPercentage() {
      if (this.latestStatus === null || !this.latestStatus.fileUploaded) {
        return 0;
      } else {
        return 100;
      }
    },
    invoicesPercentage() {
      if (
        this.latestStatus === null ||
        this.latestStatus.invoicesFailedToUpdate === null ||
        this.latestStatus.invoicesUpdated === null ||
        this.latestStatus.totalInvoices === null ||
        this.latestStatus.totalInvoices === 0
      ) {
        return 0;
      }
      let percent = Math.floor(
        ((this.latestStatus.invoicesFailedToUpdate +
          this.latestStatus.invoicesUpdated) /
          this.latestStatus.totalInvoices) *
          100
      );
      return percent;
    },
    completed() {
      return this.latestStatus && this.latestStatus.status === "Completed";
    },
    failed() {
      return this.latestStatus && this.latestStatus.status === "Failed";
    },
    percentage() {
      if (this.completed) {
        return 100;
      }
      return Math.floor(
        (this.creatingFilesPercentage +
          this.uploadingFilesPercentage +
          this.invoicesPercentage) / 3
      );
    }
  },
  watch: {
    latestStatus(newVal, oldVal) {
      if (newVal.status !== "Completed" && newVal.status !== "Failed") {
        return;
      }
      if (!oldVal) {
        this.reloadFeeResultsAccountsTable();
        return;
      }
      if (newVal.id !== oldVal.id) {
        this.reloadFeeResultsAccountsTable();
        return;
      }
      if (oldVal.status !== "Completed" && oldVal.status !== "Failed") {
        this.reloadFeeResultsAccountsTable();
        return;
      }
    }
  },
  methods: {
    ...mapMutations("feeResultsAccounts", [
      "resetPagination",
      "resetSelectedAccounts"
    ]),
    ...mapMutations("dialogs", ["setPayoutExportStatusError"]),
    ...mapActions("exports", ["getLatestPayoutExportStatus"]),
    reloadFeeResultsAccountsTable() {
      this.resetSelectedAccounts();
      this.resetPagination();
    },
    async showDetailsInfo() {
      await this.getLatestPayoutExportStatus();
      this.showPayoutExportInfo = true;
    },
    async toggleStatus() {
      if (!this.showPayoutExportStatus) {
        await this.getLatestPayoutExportStatus();
      }
      this.togglePayoutExportStatus();
    },
  }
};
</script>
<style scoped>
.color-active {
  color: teal;
}
</style>
