<template>
  <v-dialog v-model="show" max-width="650" @keydown.esc="close">
    <v-card>
      <v-card-title>
        <span class="headline"> Latest Custodian Export Details </span>
        <span class="right-corner">
          <refresh-icon
            :getLatestStatus="getLatestCustodianExportStatus"
          ></refresh-icon>
          <v-icon @click="close" class="exit-icon after-disabled">
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <div>
        <v-card v-if="latestStatus">
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Started At </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ formatStartedAtTime(latestStatus) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Total Custodians </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ totalCustodians(latestStatus) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Finished Custodians </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ finishedCustodians(latestStatus) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Creating File </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <progress-percentage
                :getLatestStatus="getLatestCustodianExportStatus"
                :showRefreshIcon="false"
                :percentage="creatingFilesPercentage"
              ></progress-percentage>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Total Files To Upload </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ totalFilesToUpload(latestStatus) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Total Files Uploaded </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ totalFilesUploaded(latestStatus) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Total Files Failed</v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ totalFailedFiles(latestStatus) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Uploading Files </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <progress-percentage
                :getLatestStatus="getLatestCustodianExportStatus"
                :showRefreshIcon="false"
                :percentage="uploadingFilesPercentage"
              ></progress-percentage>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Total Invoices </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ totalInvoices(latestStatus) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Invoices Updated </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ updatedInvoices(latestStatus) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Failed Invoices To Update </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ failedInvoices(latestStatus) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Updating Invoices </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <progress-percentage
                :getLatestStatus="getLatestCustodianExportStatus"
                :showRefreshIcon="false"
                :percentage="invoicesUploadingPercentage"
              ></progress-percentage>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="showFinishedAtRow">
            <v-col cols="12" md="6">
              <v-card-text> Finished At </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ formatFinishedAtTime(latestStatus) }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> Status </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text :style="textColor(latestStatus.status)">
                {{ exportCustodianStatus(latestStatus) }}
                <v-btn
                  small
                  v-if="completed"
                  class="ml-5"
                  :color="colorTheme.button"
                  dark
                  @click="goToExportPage()"
                >
                  Go to Exports
                </v-btn>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="failed">
            <v-col cols="12" md="6">
              <v-card-text> Fail message </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                {{ failMessage }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card>
        <v-card v-else>
          <v-card-text> No Data </v-card-text>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { formatUtcDateTime, insertWhitespaces } from "@/utils/format-util.js";

import ProgressPercentage from "@/components/common/ProgressPercentage.vue";
import RefreshIcon from "@/components/common/RefreshIcon.vue";

export default {
  components: {
    ProgressPercentage,
    RefreshIcon
  },
  props: [
    "showDialog",
    "invoicesUploadingPercentage",
    "creatingFilesPercentage",
    "uploadingFilesPercentage"
  ],
  emits: ["update:showDialog"],
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme", "exportColors"]),
    ...mapState("exports", ["latestCustodianExportStatus"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    latestStatus() {
      return this.latestCustodianExportStatus;
    },
    completed() {
      return this.latestStatus && this.latestStatus.status === "Completed";
    },
    showFinishedAtRow() {
      if (this.latestStatus !== null && this.latestStatus.finishedAt !== null) {
        return true;
      } else {
        return false;
      }
    },
    failed() {
      return this.latestStatus && this.latestStatus.status === "Failed";
    },
    failMessage() {
      if (this.latestStatus && this.latestStatus.failMessage) {
        return this.latestStatus.failMessage;
      } else {
        return "???";
      }
    }
  },
  methods: {
    ...mapMutations("exports", ["setLatestCustodianExportStatus"]),
    ...mapActions("exports", ["getLatestCustodianExportStatus"]),
    close() {
      this.show = false;
    },
    totalCustodians(date) {
      if (date === null || date.totalCustodians === null) {
        return "???";
      }
      return date.totalCustodians;
    },
    finishedCustodians(date) {
      if (date === null || date.custodiansFinished === null) {
        return "???";
      }
      return date.custodiansFinished;
    },
    totalFilesToUpload(files) {
      if (files === null || files.totalFilesToUpload === null) {
        return "???";
      }
      return files.totalFilesToUpload;
    },
    totalFilesUploaded(files) {
      if (files === null || files.filesUploaded === null) {
        return "???";
      }
      return files.filesUploaded;
    },
    totalFailedFiles(files) {
      if (files === null || files.filesFailedToUpload === null) {
        return "???";
      }
      return files.filesFailedToUpload;
    },
    totalInvoices(invoices) {
      if (invoices === null || invoices.totalInvoices === null) {
        return "???";
      }
      return invoices.totalInvoices;
    },
    updatedInvoices(invoices) {
      if (invoices === null || invoices.invoicesUpdated === null) {
        return "???";
      }
      return invoices.invoicesUpdated;
    },
    failedInvoices(invoices) {
      if (invoices === null || invoices.invoicesFailedToUpdate === null) {
        return "???";
      }
      return invoices.invoicesFailedToUpdate;
    },
    exportCustodianStatus(status) {
      if (status === null || status.status === null) {
        return "???";
      }
      if (status.status === "UpdatingInvoices") {
        return "Uploading Files, Updating Invoices";
      }
      return insertWhitespaces(status.status);
    },
    textColor(status) {
      return {
        color: this.exportColors[status],
        "font-weight": "bolder"
      };
    },
    formatStartedAtTime(latestStatus) {
      if (latestStatus === null || latestStatus.startedAt === null) {
        return "???";
      }
      return formatUtcDateTime(latestStatus.startedAt);
    },
    formatFinishedAtTime(latestStatus) {
      if (latestStatus === null || latestStatus.finishedAt === null) {
        return "???";
      } else {
        return formatUtcDateTime(latestStatus.finishedAt);
      }
    },
    goToExportPage() {
      let newRoute = this.$router.resolve({ name: "DownloadPortal" });
      window.open(newRoute.href);
    },
  }
};
</script>
