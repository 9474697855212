<template>
  <v-container style="width: 250px; display: flex; margin-left: inherit" class="pr-0 pl-0">
    <v-progress-linear
      v-if="!failed"
      :value="percentage"
      height="25"
      :color="colorTheme.progress"
      rounded
    >
      <strong>{{ Math.ceil(percentage) }}%</strong>
      <v-icon v-if="showWarningIcon" class="warning-icon">
        mdi-alert
      </v-icon>
    </v-progress-linear>
    <v-progress-linear
      v-else
      :value="percentage"
      height="25"
      :color="colorTheme.error"
      rounded
    >
      <strong> FAILED </strong>
    </v-progress-linear>
    <refresh-icon
      v-if="showRefreshIcon"
      :getLatestStatus="getLatestStatus"
    ></refresh-icon>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import RefreshIcon from "./RefreshIcon.vue";
export default {
  props: [
    "getLatestStatus",
    "showRefreshIcon",
    "percentage",
    "failed",
    "showWarningIcon",
  ],
  components: { RefreshIcon },
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
  },
};
</script>

<style scoped>
.warning-icon {
  color: orange;
}
</style>
