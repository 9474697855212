const feeReviewIcons = {
  custodianIcon: `<svg width="25" cursor="pointer" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_8376_11361" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
    <rect x="0.563477" y="0.909424" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_8376_11361)">
    <path d="M3.42895 21.4862C3.15281 21.4862 2.92896 21.2624 2.92896 20.9862V5.49139C2.92896 5.33112 3.00578 5.18057 3.13555 5.08653L7.38553 
    2.00657C7.56056 1.87973 7.7973 1.87973 7.97233 2.00657L12.2223 5.08653C12.3521 5.18057 12.4289 5.33112 12.4289 5.49139V7.48627C12.4289 7.76241 
    12.6528 7.98627 12.9289 7.98627H21.6981C21.9743 7.98627 22.1981 8.21013 22.1981 8.48627V20.9862C22.1981 21.2624 21.9743 21.4862 21.6981 21.4862H3.42895ZM4.42891 
    19.4863C4.42891 19.7624 4.65276 19.9863 4.92891 19.9863H6.42893C6.70507 19.9863 6.92893 19.7624 6.92893 19.4863V17.9862C6.92893 17.7101 6.70507 17.4862 6.42893 
    17.4862H4.92891C4.65276 17.4862 4.42891 17.7101 4.42891 17.9862V19.4863ZM4.42891 15.4863C4.42891 15.7624 4.65276 15.9863 4.92891 15.9863H6.42893C6.70507 15.9863 
    6.92893 15.7624 6.92893 15.4863V13.9862C6.92893 13.7101 6.70507 13.4862 6.42893 13.4862H4.92891C4.65276 13.4862 4.42891 13.7101 4.42891 13.9862V15.4863ZM4.42891 
    11.4863C4.42891 11.7624 4.65276 11.9863 4.92891 11.9863H6.42893C6.70507 11.9863 6.92893 11.7624 6.92893 11.4863V9.98625C6.92893 9.7101 6.70507 9.48625 6.42893 
    9.48625H4.92891C4.65276 9.48625 4.42891 9.7101 4.42891 9.98625V11.4863ZM4.42891 7.48627C4.42891 7.76241 4.65276 7.98627 4.92891 7.98627H6.42893C6.70507 7.98627 
    6.92893 7.76241 6.92893 7.48627V5.98625C6.92893 5.7101 6.70507 5.48625 6.42893 5.48625H4.92891C4.65276 5.48625 4.42891 5.7101 4.42891 5.98625V7.48627ZM8.42891 
    7.48627C8.42891 7.76241 8.65276 7.98627 8.92891 7.98627H10.4289C10.7051 7.98627 10.9289 7.76241 10.9289 7.48627V5.98625C10.9289 5.7101 10.7051 5.48625 10.4289 
    5.48625H8.92891C8.65276 5.48625 8.42891 5.7101 8.42891 5.98625V7.48627ZM8.42891 19.4863C8.42891 19.7624 8.65276 19.9863 8.92891 19.9863H20.1982C20.4743 19.9863 
    20.6982 19.7624 20.6982 19.4863V9.98625C20.6982 9.7101 20.4743 9.48625 20.1982 9.48625H8.92891C8.65276 9.48625 8.42891 9.7101 8.42891 9.98625V19.4863ZM15.1212 
    13.4862C14.8451 13.4862 14.6212 13.2624 14.6212 12.9862V12.4863C14.6212 12.2101 14.8451 11.9863 15.1212 11.9863H17.8135C18.0896 11.9863 18.3135 12.2101 18.3135 
    12.4863V12.9862C18.3135 13.2624 18.0896 13.4862 17.8135 13.4862H15.1212ZM15.1212 17.4862C14.8451 17.4862 14.6212 17.2624 14.6212 16.9862V16.4863C14.6212 16.2101 
    14.8451 15.9863 15.1212 15.9863H17.8135C18.0896 15.9863 18.3135 16.2101 18.3135 16.4863V16.9862C18.3135 17.2624 18.0896 17.4862 17.8135 17.4862H15.1212ZM11.4289 
    13.4862C11.1528 13.4862 10.9289 13.2624 10.9289 12.9862V12.4863C10.9289 12.2101 11.1528 11.9863 11.4289 11.9863H11.9289C12.205 11.9863 12.4289 12.2101 12.4289 
    12.4863V12.9862C12.4289 13.2624 12.205 13.4862 11.9289 13.4862H11.4289ZM11.4289 17.4862C11.1528 17.4862 10.9289 17.2624 10.9289 16.9862V16.4863C10.9289 16.2101 
    11.1528 15.9863 11.4289 15.9863H11.9289C12.205 15.9863 12.4289 16.2101 12.4289 16.4863V16.9862C12.4289 17.2624 12.205 17.4862 
    11.9289 17.4862H11.4289Z" fill="black" fill-opacity="0.6"/>
    </g>
    </svg>`,
  payoutIcon: `<svg width="24" cursor="pointer" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_8476_10603" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
    <rect y="0.0961914" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_8476_10603)">
    <path d="M6.25 14.8461H13.8943L15.3942 13.3462H6.25V14.8461ZM6.25 10.8461H11.7499V9.34619H6.25V10.8461ZM3.99998 7.09617V17.0962H11.6442L10.1443 
    18.5961H3.5C2.94772 18.5961 2.5 18.1484 2.5 17.5961V6.59619C2.5 6.04391 2.94772 5.59619 3.5 5.59619H20.5C21.0522 5.59619 21.5 6.04391 21.5 
    6.59619V8.74044H20V7.09617H3.99998ZM22.2269 12.2231C22.3038 12.3 22.3422 12.3853 22.3422 12.4788C22.3422 12.5724 22.3038 12.6577 22.2269 12.7346L21.3846 
    13.5769L20.1154 12.3077L20.9577 11.4654C21.0346 11.3885 21.1198 11.35 21.2134 11.35C21.307 11.35 21.3923 11.3885 21.4692 11.4654L22.2269 12.2231ZM13.1538 
    20.5577V19.2885L19.5346 12.9077L20.8038 14.1769L14.423 20.5577H13.1538Z" fill="black" fill-opacity="0.6"/>
    </g>
    </svg>`
};

export default feeReviewIcons;
