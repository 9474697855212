<template>
  <v-dialog v-model="show" max-width="650" @keydown.esc="close">
    <v-card>
      <v-card-title>
        <span class="headline"> Error log </span>
        <span class="right-corner">
          <v-icon @click="close" class="exit-icon after-disabled">
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-data-table
        :headers="errorLogHeaders"
        :items="failLog"
        item-key="id"
        :hide-default-footer="failLog.length <= 10"
      >
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["showDialog", "failLog"],
  emits: ["update:showDialog", "update:failLog"],
  data: () => ({
    errorLogHeaders: [
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false
      },
      {
        text: "Fail Message",
        value: "failMessage",
        sortable: false
      }
    ]
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
  },
  methods: {
    close() {
      this.show = false;
      this.$emit("update:failLog", []);
    },
  },
};
</script>
